import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SidebarLink from './SidebarLink';
import Roles from '../../../config/roles';
import SidebarCategory from "./SidebarCategory";

const SidebarContent = ({
	onClick, sidebarCollapse
}) => {
	const userRole = useSelector(state => state.auth.user.role);

	const hideSidebar = () => {
		onClick();
	};

	const renderDashboard = () => {
		return (
			<SidebarCategory
				title="Dashboard"
				route="/dashboard"
				icon="home"
				onClick={hideSidebar}
				sidebarCollapse={sidebarCollapse}
			/>
		)
	}

	const renderProfile = () => {
		if(userRole !== Roles.CLIENT && userRole !== Roles.GUEST) {
			return (
				<SidebarCategory
					title="My Records"
					icon="list"
					onClick={hideSidebar}
					sidebarCollapse={sidebarCollapse}>
					{
						userRole !== Roles.CLIENT && (
							<SidebarLink
								title="Reports"
								route="/dashboard/my-reports"
								onClick={hideSidebar}
							/>
						)
					}
					{
						(userRole !== Roles.CLIENT || userRole !== Roles.GUEST) && (
							<SidebarLink
								title="Salary"
								route="/dashboard/profile/my-salary"
								onClick={hideSidebar}
							/>
						)
					}
					{
						userRole !== Roles.CLIENT ? (
							<SidebarLink
								title="Invoices"
								route="/dashboard/profile/my-invoices"
								onClick={hideSidebar}
							/>
						) : null
					}
					{
						(userRole !== Roles.GUEST && userRole !== Roles.CLIENT) && (
							<SidebarLink
								title="Vacations"
								route="/dashboard/profile/vacations-history"
								onClick={hideSidebar}
							/>
						)
					}
					{
						(userRole === Roles.ADMIN) && (
							<SidebarLink
								title="Financial Activity"
								route="/dashboard/financial-activity"
								onClick={hideSidebar}
							/>
						)
					}
					{
						(userRole !== Roles.CLIENT || userRole !== Roles.GUEST) && (
							<SidebarLink
								title="Bank Account"
								route="/dashboard/bank-account"
								onClick={hideSidebar}
							/>
						)
					}
				</SidebarCategory>
			);
		}
	};

	const renderCrm = () => {
		if (userRole === Roles.ADMIN || userRole === Roles.SALES) {
			return (
				<SidebarCategory
					title="CRM"
					icon="list"
					onClick={hideSidebar}
					sidebarCollapse={sidebarCollapse}>
					<SidebarLink
						title="Leads"
						route="/dashboard/leads-page"
						onClick={hideSidebar}
					/>
					<SidebarLink
						title="New Lead"
						route="/dashboard/add-a-new-lead"
						onClick={hideSidebar}
					/>
				</SidebarCategory>
			);
		}
	}

	const renderReportsManagement = () => {
		return (
			(userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES || userRole === Roles.CLIENT) && (
				<SidebarCategory
					title="Reports Management"
					icon="list"
					route="/dashboard/management"
					onClick={hideSidebar}
					sidebarCollapse={sidebarCollapse}
				/>
			)
		)
	}

	const renderUsersManagement = () => {
		return (
			<SidebarCategory
				title="Users Management"
				icon="list"
				route="/dashboard/users"
				onClick={hideSidebar}
				sidebarCollapse={sidebarCollapse}
			/>
		)
	}

	const renderProjectsManagement = () => {
		return (
			(userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES || userRole === Roles.CLIENT) && (
				<SidebarCategory
					title="Projects Management"
					icon="list"
					route="/dashboard/projects"
					onClick={hideSidebar}
					sidebarCollapse={sidebarCollapse}
				/>
			)
		)
	}

	const renderFinancials = () => {
		return (
			<SidebarCategory
				title="Financials"
				icon="list"
				onClick={hideSidebar}
				sidebarCollapse={sidebarCollapse}>
				{
					(userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES) && (
						<SidebarLink
							title="Invoices"
							route="/dashboard/invoices"
							onClick={hideSidebar}
						/>
					)
				}
				{
					(userRole === Roles.ADMIN || userRole === Roles.FIN) && (
						<SidebarLink
							title="Salaries"
							route="/dashboard/salary-reports"
							onClick={hideSidebar}
						/>
					)
				}
				{
          (userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES) && (
						<SidebarLink
							title="Reports"
							route="/dashboard/financial-reports"
							onClick={hideSidebar}
						/>
					)
				}
			</SidebarCategory>
		);
	}

	const renderSettings = () => {
			return (
				(userRole === Roles.ADMIN || userRole === Roles.CLIENT) && (
					<SidebarCategory
						title="Settings"
						icon="list"
						onClick={hideSidebar}
						sidebarCollapse={sidebarCollapse}
					>
						{
							(userRole === Roles.ADMIN) && (
								<>
									<SidebarLink
										title="Email Templates"
										route="/dashboard/settings/email-templates"
										onClick={hideSidebar}
									/>
									<SidebarCategory
										title="System"
										onClick={hideSidebar}
										sidebarCollapse={sidebarCollapse}
									>
										<SidebarLink
											title="SaaS Clients"
											route="/dashboard/saas/clients"
											onClick={hideSidebar}
										/>
									</SidebarCategory>
								</>
							)
						}
						{
							(userRole === Roles.CLIENT) && (
								<SidebarLink
									title="My Bank Account"
									route="/dashboard/settings/my-bank-account"
									onClick={hideSidebar}
								>
								</SidebarLink>
							)
						}
					</SidebarCategory>
				)
			)

	}

	const renderAccount = () => {
		return (
			<SidebarCategory
				title="Account"
				icon="list"
				onClick={hideSidebar}
				sidebarCollapse={sidebarCollapse}>
				<SidebarLink
					title="Account"
					route="/dashboard/settings"
					onClick={hideSidebar}
				/>
				{
					(userRole === Roles.ADMIN) && (
						<SidebarLink
							title="System"
							route="/dashboard/system"
							onClick={hideSidebar}
						/>
					)
				}
				{
					(userRole !== Roles.CLIENT) && (
						<SidebarLink
							title="My Reviews"
							route="/dashboard/my-reviews"
							onClick={hideSidebar}
						/>
					)
				}
				{
					(userRole === Roles.ADMIN) && (
						<SidebarLink
							title="Businesses"
							route="/dashboard/businesses"
							onClick={hideSidebar}
						/>
					)
				}
			</SidebarCategory>
		);
	}

	return (
		<div className="sidebar__content">
			<ul className="sidebar__block">
				{renderDashboard()}
				{renderProfile()}
				{renderCrm()}
				{renderReportsManagement()}
				{renderUsersManagement()}
				{renderProjectsManagement()}
				{(userRole === Roles.ADMIN || userRole === Roles.FIN || userRole === Roles.SALES) && renderFinancials()}
				{renderSettings()}
				{renderAccount()}
			</ul>
		</div>
	);
};

SidebarContent.propTypes = {
	onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
