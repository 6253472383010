import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import renderFileInputField from '../../../../shared/components/form/FileInput';
import { getLead } from '../../../../utils/api';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { initialize } from 'redux-form';
import { renderField } from '../../../../utils/helpers';
import { useSelector } from 'react-redux';


const renderFieldWithMask = ({
  input,
  placeholder,
  type,
  mask,
  className,
  guide = true,
  pipe,
  keepCharPositions = false,
}) => {
  return (
    <MaskedInput
      {...input}
      placeholder={placeholder}
      type={type}
      mask={mask}
      className={className}
      guide={guide}
      pipe={pipe}
      keepCharPositions={keepCharPositions}
    />
  );
};

let ProspectForm = ({
  modal,
  toggle,
  handleSubmit,
  selectedFile,
  setSelectedFile,
  leadId,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.user);

  const numberMask = createNumberMask({
    prefix: '$ ',
    allowDecimal: true,
    allowLeadingZeroes: true,
  });

  useEffect(() => {
    if (!modal) return;

    getLead(leadId, authUser.accessToken).then((res) => {
      if(res.data.success) {
        dispatch(initialize('prospect_form',{
          budget: res?.data?.data?.budget,
          start_date: res?.data?.data?.start_date ? moment(new Date(res?.data?.data?.start_date)).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
        }));
      }
    });
  }, [modal, leadId, dispatch, authUser.accessToken]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      modalClassName="ltr-support"
      className="modal-dialog--primary theme-light"
    >
      <div className="modal__header">
        <h4 className="text-modal  modal__title">
          {t('leads.prospect_form.title_form')}
        </h4>
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggle}
        />
      </div>
      <div className=" modal__body">
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.prospect_form.title_input_budget')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="budget"
                component={renderFieldWithMask}
                type="text"
                mask={numberMask}
                placeholder="Budget"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.prospect_form.title_input_start_date')}
            </span>
            <div className="form__form-group-field">
                <Field
                  name="start_date"
                  component={renderField}
                  type="date"
                  className="input-without-border-radius"
                  required
                />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t('leads.prospect_form.title_input_comment')}
            </span>
            <div className="form__form-group-field">
              <Field
                name="comment"
                component="textarea"
                type="text"
                placeholder="Comment"
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t('leads.prospect_form.title_input_comment_file')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="comment_file"
                  value={selectedFile}
                  onChange={(e) => setSelectedFile(e)}
                  component={renderFileInputField}
                />
              </div>
            </div>
          </div>
          <ButtonToolbar>
            <Button color="primary" type="submit">
              {t('leads.prospect_form.title_button')}
            </Button>
          </ButtonToolbar>
        </form>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: 'prospect_form',
})(ProspectForm);
