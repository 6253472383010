import React, { useEffect, useState } from 'react';
import { Field, reduxForm} from 'redux-form';
import { Button, Col, Row } from 'reactstrap';
import validate from './validate';
import renderSelectField from '../../../../../../shared/components/form/Select';
import CustomModal from '../../../../../../shared/components/customComponents/CustomModal/CustomModal';
import { getAllUsers } from '../../../../../../utils/api';
import {renderField} from '../../../../../../utils/helpers';
import { useSelector } from 'react-redux';


const FormCreateSalaryList = ({ handleSubmit }) => {
  const [employee, setEmployee] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    getAllUsers(authUser.accessToken).then((res) => {
      if(res.data.success) {
        return setEmployee(res.data.data.users);
      }

      setErrorModal(!errorModal);
      setErrorMessage(res.data.errors[0].message);
    })
  }, [errorModal, authUser.accessToken]);

  return(
    <form onSubmit={handleSubmit}>
      <div className="form_Settings">
        <Row className="w-100">
          <Col sm={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">EMPLOYEE</span>
              <div className="form__form-group-field">
                <Field
                  name="selectEmployee"
                  component={renderSelectField}
                  id="status"
                  options={employee?.map(u => {
                    return {
                      value: u.id,
                      label: u.first_name + ' ' + u.last_name
                    }
                  })}
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">BONUSES, $</span>
              <div className="form__form-group-field">
                <Field
                  name="bonuses"
                  component={renderField}
                  type="text"
                  className="input-without-border-radius"
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">HOSPITAL DAYS</span>
              <div className="form__form-group-field">
                <Field
                  name="hospital_days"
                  component={renderField}
                  type="text"
                  className="input-without-border-radius"
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">OVERTIME DAYS</span>
              <div className="form__form-group-field">
                <Field
                  name="overtime_days"
                  component={renderField}
                  type="text"
                  className="input-without-border-radius"
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">OTHER SURCHARGES, $</span>
              <div className="form__form-group-field">
                <Field
                  name="other_surcharges"
                  component={renderField}
                  type="text"
                  className="input-without-border-radius"
                />
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="form__form-group">
              <span className="form__form-group-label">VACATION DAYS</span>
              <div className="form__form-group-field">
                <Field
                  name="vacation_days"
                  component={renderField}
                  type="text"
                  className="input-without-border-radius"
                />
              </div>
            </div>
          </Col>
          <Col sm={12}>
            <div className="form_Settings-button">
              <Button type="submit" color="primary" className="account__btn">
                Save
              </Button>
            </div>
          </Col>

          <CustomModal
            successModal={errorModal}
            toggleCloseModal={() => setErrorModal(!errorModal)}
            color={"danger"}
            textModal={errorMessage}
          />
        </Row>
      </div>
    </form>
  )
}

export default reduxForm({
  form: "FormCreateSalaryList",
  validate
})(FormCreateSalaryList);