import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllUsers } from '../../../utils/api';
import { Col, Container, Row } from 'reactstrap';
import FinancialActivityFilterForm from './components/FinancialActivityFilterForm/FinancialActivityFilterForm';
import moment from 'moment/moment';
import DataReactTable from '../../Tables/DataTable/components/DataReactTable';
import { useDispatch, useSelector } from 'react-redux';
import { columns } from './components/FinancialActivityDataColumns';
import { getFinancialActivityFetchingAction } from '../../../redux/actions/financialActivityActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';


const FinancialActivity = () => {
  const { t } = useTranslation('common');
  const {financialActivity, errors, totalRecords} = useSelector(state => state.financialActivity);
  const user = useSelector(state => state.auth.user);
  const [users, setUsers] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const fromDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const [query, setQuery] = useState({start: 0, limit: 10, from_date: fromDate, to_date: toDate });
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    getAllUsers(user.accessToken).then(res => setUsers(res.data.data.users))
  }, []);

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getFinancialActivityFetchingAction(userId, updatedQuery));
  }, [currentPage, pageLimit, dispatch, query, userId, location.pathname, navigate]);

  const resetFilterForm = () => {
    setUserId('');
    return setQuery({...query, from_date: fromDate, to_date: toDate});
  }

  const workHistoryFilter = (value) => {
    setUserId(value?.selectReporter?.value);
    return setQuery({...query, from_date: moment(value?.from_date).format('YYYY-MM-DD'), to_date: moment(value?.to_date).format('YYYY-MM-DD')});
  }

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  return (
    <Container>
      <Row>
        <FinancialActivityFilterForm users={users} onSubmit={workHistoryFilter} resetForm={resetFilterForm} />
        <Col className="col-12">
          <DataReactTable
            tableName={"Salary"}
            pagination={!userId ? {
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit,
            } : false}
            sortTable={true}
            search={false}
            emptyStateMessage={t('empty_states.activity')}
            reactTableData={{tableHeaderData: columns, tableRowsData: financialActivity?.map((c) => {
              return {...c, first_name: c.user.first_name, last_name: c.user.last_name}
            })}}
          />
          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>
      </Row>
    </Container>
  );
};

export default FinancialActivity;