import { useEffect, useState } from 'react';
import { SelectField } from '../../../../../../shared/components/form/Select';
import { useSelector } from 'react-redux';
import { getUsers } from '../../../../../../utils/api';
import roles from '../../../../../../config/roles';

let timerId;

const AddUserForm = ({ selected, onChange, name }) => {
  const { users: stateUsers, totalRecords: initialTotalRecords } = useSelector(
    (state) => state.users,
  );
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState('');
  const authUser = useSelector(state => state.auth.user);

  useEffect(() => {
    if (!stateUsers.length) {
      return;
    }

    const initialUsers = stateUsers
      .filter((user) => {
        return user.role !== roles.CLIENT;
      })
      .slice(0, 10)
      .map(({ id, first_name, last_name }) => {
        return {
          id,
          value: id,
          label: `${first_name} ${last_name}`,
        };
      });

    setUsers(initialUsers);
    setTotal(initialTotalRecords);
    setLoaded(true);
  }, [stateUsers, initialTotalRecords]);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    setError('');

    const query = {
      is_active: 1,
      'roles[0]': roles.ADMIN,
      'roles[1]': roles.DEV,
      'roles[2]': roles.FIN,
      'roles[3]': roles.PM,
      'roles[4]': roles.SALES,
      search_query: searchQuery,
    };

    getUsers(query, authUser.accessToken)
      .then(({ data }) => {
        if (!data.success) {
          setError(data.errors[0].message);
        } else {
          setUsers(
            data.data.users.map(({ id, first_name, last_name }) => {
              return {
                id,
                value: id,
                label: `${first_name} ${last_name}`,
              };
            }),
          );
        }
      })
      .catch((e) => {
        setError(e?.message);
      });
  }, [searchQuery, authUser.accessToken, loaded]);

  return (
    <>
      <div className="form__form-group-field">
        <SelectField
          name={name}
          value={selected}
          onChange={onChange}
          options={[
            {
              value: '',
              label: 'Not set',
            },
            ...users,
          ]}
          onInputChange={(value) => {
            if (total <= 10) return;

            clearTimeout(timerId);

            timerId = setTimeout(() => {
              setSearchQuery(value);
            }, 600);
          }}
        />
      </div>
      {error && <div className={'form__form-group-error'}>{error}</div>}
    </>
  );
};

export default AddUserForm;
