import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Col, Container, Row} from "reactstrap";
import DataReactTable from "../../../../Tables/DataTable/components/DataReactTable";
import {getMySalaryHistoryFetchingAction} from "../../../../../redux/actions/mySalaryHistoryAction";
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { change } from 'redux-form';
import TableConfig from './MySalaryDataColumns';
import MySalaryFilters from './MySalaryFilters';
import roles from '../../../../../config/roles';

const MySalary = () => {
  const { t } = useTranslation('common');
  const { mySalary, totalRecords } = useSelector((state) => state.mySalary);
  const authUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    start: 0,
    limit: 10,
    search_query: '',
    id: authUser.userId,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);

  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit,
    };

      navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
      dispatch(getMySalaryHistoryFetchingAction(updatedQuery));
    }, [currentPage, pageLimit, dispatch, query, location.pathname, navigate]);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const resetFilterForm = () => {
    dispatch(change('my_salary_form', 'search', ''));
    dispatch(change('my_salary_form', 'userId', ''));
    setQuery((prev) => {
      return {
        ...prev,
        search_query: '',
        id: authUser.userId,
      };
    });
  };

  const handlerFilterForm = (value) => {
    setQuery((prev) => {
      return {
        ...prev,
        search_query: value.search ?? '',
        id: value.userId ? value.userId.id : authUser.userId,
      };
    });
  };

  const columns = TableConfig().columns;

  return (
    <Container>
      <Row>
        {(authUser.role === roles.ADMIN || authUser.role === roles.FIN) && (
          <MySalaryFilters
            resetForm={resetFilterForm}
            onSubmit={handlerFilterForm}
          />
        )}
        <Col>
          <DataReactTable
            tableName={t('my_salary.table_title')}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit,
            }}
            sortTable={true}
            emptyStateMessage={t('empty_states.salary')}
            reactTableData={{
              tableHeaderData: columns,
              tableRowsData: mySalary?.map((c, index) => {
                return {
                  ...c,
                  id: `${index + 1}`,
                  salary: `$${c.salary}`,
                  bonuses: `$${c.bonuses}`,
                  day_off: `${c.day_off} days`,
                  hospital_value: `$${c.hospital_value}`,
                  vacation_value: `$${c.vacation_value}`,
                  other_surcharges: `$${c.other_surcharges}`,
                  subtotal: `$${c.subtotal}`,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MySalary;
