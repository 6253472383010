import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { isJsonString } from '../../../../../utils/helpers';

const GraphBar = ({ data }) => {
  const [canvasHeight, setCanvasHeight] = useState('800px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setCanvasHeight('600px');
      } else {
        setCanvasHeight('800px');
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const customTooltip = (tooltipModel) => {
    let tooltipEl = document.getElementById('custom-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.getElementById('custom-tooltip');
      tooltipEl.id = 'custom-tooltip';
      document.body.appendChild(tooltipEl);
    }

    if (!tooltipModel || tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    const value =
      tooltipModel.dataPoints[0].dataset.dataTooltips[
        tooltipModel.dataPoints[0].dataIndex
      ];

    if (!value) {
      return;
    }

    let innerHtml = `<b>${tooltipModel.dataPoints[0].label}</b><br/>`;

    if (isJsonString(value.notes)) {
      const notes = JSON.parse(value.notes);

      if (notes.length) {
        innerHtml += `
          Notes:<br/>
          ${notes.reduce(
            (result, current) => result + `${current.note}<br/>`,
            '',
          )}
      `;
      }
    } else if (value.notes !== '') {
      innerHtml += `
        Notes:<br/>
        ${value.notes}<br/>
      `;
    }

    innerHtml += `
        Score A: ${value['Score A']}/100<br/>
        Score B: ${value['Score B']}/100<br/>
        Score C: ${value['Score C']}/100
    `;

    tooltipEl.innerHTML = innerHtml;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = tooltipModel.caretX + 'px';
    tooltipEl.style.top = tooltipModel.caretY - tooltipEl.clientHeight + 'px';
  };

  return (
    <div style={{ width: '100%', height: canvasHeight }}>
      <Bar
        data={data}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
            },
            y: {
              beginAtZero: true,
              max: 100,
            },
          },
          barPercentage: 0.4,
          plugins: {
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: (context) => {
                customTooltip(context.tooltip);
              },
            },
            datalabels: {
              anchor: 'end',
              align: 'end',
              formatter: (value) => value + '%',
              color: 'black',
              font: {
                weight: 'bold',
              },
            },
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'My Reviews',
              font: {
                weight: 'bold',
                size: '18px',
              },
              padding: {
                top: 0,
                bottom: 30,
              },
            },
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  label: {
                    content: 'Bad performance',
                    display: true,
                    position: 'start',
                    backgroundColor: 'transparent',
                    color: 'red',
                    yAdjust: 10,
                  },
                  yMin: 40,
                  yMax: 40,
                  borderColor: 'transparent',
                  borderWidth: 2,
                },
                line2: {
                  type: 'line',
                  label: {
                    content: 'Ordinary performance',
                    display: true,
                    position: 'start',
                    backgroundColor: 'transparent',
                    color: 'orange',
                    yAdjust: 10,
                  },
                  yMin: 70,
                  yMax: 70,
                  borderColor: 'orange',
                  borderWidth: 2,
                },
                line3: {
                  type: 'line',
                  label: {
                    content: 'Good performance',
                    display: true,
                    position: 'start',
                    backgroundColor: 'transparent',
                    color: 'rgb(196, 188, 33)',
                    yAdjust: 10,
                  },
                  yMin: 90,
                  yMax: 90,
                  borderColor: 'rgb(196, 188, 33)',
                  borderWidth: 2,
                },
                line4: {
                  type: 'line',
                  label: {
                    content: 'Perfect performance',
                    display: true,
                    position: 'start',
                    backgroundColor: 'transparent',
                    color: 'green',
                    yAdjust: -7,
                  },
                  yMin: 100,
                  yMax: 100,
                  borderColor: 'green',
                  borderWidth: 2,
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default GraphBar;
