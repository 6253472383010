import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import FormAddReport from './components/FormAddReport/FormAddReport';
import { deleteReport, getProjects, getReports, postCreateReport, putReport } from '../../../../utils/api';
import CustomModal from '../../../../shared/components/customComponents/CustomModal/CustomModal';
import DataReactTable from '../../../Tables/DataTable/components/DataReactTable';
import PencilIcon from 'mdi-react/PencilIcon';
import { columns } from './components/ReportReactTableColumns';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsFetchingAction } from '../../../../redux/actions/myReportsActions';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ReportsFilterForm from './components/ReportsFilterForm/ReportsFilterForm';
import { change, reset } from 'redux-form';
import ConfirmModal from '../../../../shared/components/customComponents/CustomConfirmModal/ConfirmModal';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SelectInputRedux from './components/SelectInputRedux/SelectInputRedux';
import { useLocation, useNavigate } from 'react-router-dom';
import { parse, stringify } from 'query-string';


export const validateEditFields = (values) => {
  const errors = {};

  if (!values.project_id) {
    errors.selectProject = 'Project ID can not be blank!';
  }

  if (!values.task) {
    errors.task = 'Task can not be blank!';
  }

  if (!values.hours) {
    errors.hours = 'Hours can not be blank!';
  } else if(!/^[0-9.]*$/.test(values.hours)) {
    errors.hours = 'Invalid data type';
  }

  return errors;
};


const MyReports = () => {
  const authUser = useSelector(state => state.auth.user);
  const { t } = useTranslation('common');
  const {reports, errors, totalRecords, totalHours} = useSelector(state => state.myReports);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQuery = parse(location.search);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(+parsedQuery?.start || 1);
  const [pageLimit, setPageLimit] = useState(+parsedQuery?.limit || 10);
  const [query, setQuery] = useState({start: 0, limit: 10, search_query: '', from_date: moment(new Date()).format("YYYY-MM-DD"), to_date: '', user_id: authUser.userId});
  const [reportId, setReportId] = useState(0);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [successDeleteModal, setSuccessDeleteModal] = useState(false);
  const [failedDeleteModal, setFailedDeleteModal] = useState(false);
  const [errorDeleteMessage, setErrorDeleteMessage] = useState('');
  const [editModes, setEditModes] = useState({});
  const [projects, setProjects] = useState([]);
  const [errorProjectModal, setErrorProjectModal] = useState(false);
  const [errorProjectMessage, setErrorProjectMessage] = useState('');
  const [editedData, setEditedData] = useState({});
  const [totalTodayHours, setTotalTodayHours] = useState('');
  const [errorProjectIdModal, setErrorProjectIdModal] = useState(false);
  const [errorProjectIdMessage, setErrorProjectIdMessage] = useState('');
  const [editConfirmModal, setEditConfirmModal] = useState(false);
  const [successEditModal, setSuccessEditModal] = useState(false);
  const [failedEditModal, setFailedEditModal] = useState(false);
  const [errorEditMessage, setErrorEditMessage] = useState('');
  const [validationsErrors, setValidationErrors] = useState({});
  const [totalRecordsProjects, setTotalRecordsProjects] = useState(0);
  const isInitialRender = useRef(true);
  const [queryProjects, setQueryProjects] = useState({search_query: ''});
  const notes = {
    note_one: "Approved reports are in green",
    note_two: "Not Approved reports are in grey (Non Approved reports will not be paid)"
  }


  useEffect(() => {
    const updatedQuery = {
      ...query,
      start: (currentPage - 1) * pageLimit,
      limit: pageLimit
    };

    navigate(`${location.pathname}?${stringify({start: currentPage, limit: pageLimit}, {arrayFormat: 'comma'})}`);
    dispatch(getReportsFetchingAction(updatedQuery));
  }, [dispatch, query, refreshCounter, currentPage, pageLimit, location.pathname, navigate]);

  useEffect(() => {
    const modifiedQuery = {search_query: queryProjects.search_query};

    if (!modifiedQuery.search_query) {
      modifiedQuery.ongoingOnly = true;
    }

    getProjects(modifiedQuery, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setProjects(res.data.data.projects);

        if (isInitialRender.current) {
          setTotalRecordsProjects(Number(res.data.data.total_records));
          isInitialRender.current = false;
        }

        return;
      }

      setErrorProjectModal(!errorProjectModal);
      setErrorProjectMessage(res.data.errors[0].message);
    })
  }, [errorProjectModal, queryProjects.search_query, authUser.accessToken]);

  useEffect(() => {
    getReports({user_id: authUser.userId, from_date: moment(new Date()).format("YYYY-MM-DD")}, authUser.accessToken).then((res) => {
      if(res.data.success) {
        setTotalTodayHours(res.data.data.total_hours);
      } else {
        setErrorProjectIdModal(!errorProjectIdModal);
        setErrorProjectIdMessage(res.data.errors[0].message);
      }
    })
  }, [errorProjectIdModal, authUser.userId, refreshCounter, authUser.accessToken]);

  const handleCreateReport = (value) => {
    postCreateReport(value, authUser.accessToken).then((res) => {
      if(res.data.success) {
        dispatch(reset('formAddReport'));
        setSuccessModal(true);
        setTimeout(() => {
          setRefreshCounter(refreshCounter + 1);
          setSuccessModal(false);
        }, 1000);
      }

      setErrorMessage(res.data.errors[0].message);
      setErrorModal(!errorModal);
    })
  }

  const handleDeleteReport = () => {
    deleteReport(reportId, authUser.accessToken).then(res => {
      if(!res.data.success) {
        setErrorDeleteMessage(res.data.errors[0].message);
        setFailedDeleteModal(!failedDeleteModal);
      } else {
        setSuccessDeleteModal(!successDeleteModal);
      }
    })
  }

  const handleEditReport = (reportId, updatedData) => {
    putReport(reportId, updatedData, authUser.accessToken).then(res => {
      if(!res.data.success) {
        setErrorEditMessage(res.data.errors[0].message);
        setFailedEditModal(!failedEditModal);
      } else {
        setSuccessEditModal(!successEditModal);
      }
    })
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangePageSize = (newSize) => {
    setCurrentPage(1);
    setPageLimit(newSize);
  };

  const resetFilterForm = () => {
    dispatch(change('reportsFilterForm', 'search', ''));
    dispatch(change('reportsFilterForm', 'from_date', ''));
    dispatch(change('reportsFilterForm', 'to_date', ''));

    return setQuery({...query, search_query: '', from_date: '', to_date: ''});
  }

  const handlerSearchReportsFilterForm = (value) => {
    return setQuery({
      ...query,
      search_query: value.search,
      from_date: moment(value.from_date).format('YYYY-MM-DD'),
      to_date: moment(value.to_date).format('YYYY-MM-DD')
    });
  }

  const handleEditClick = () => {
    setEditModes(editedData.report_id);

    setEditedData((prevEditData) => ({
      ...prevEditData,
      task: editedData.task,
      editProject: editedData.project.id,
      hour: timeToDecimal(editedData.hour)
    }))
  };

  const handleSaveClick = (report) => {
    const updatedData = {
      hours: editedData.hour,
      project_id: editedData.editProject,
      task: editedData.task,
    };

    const errors = validateEditFields(updatedData);

    if (!_.isEmpty(errors)) {
      return setValidationErrors(errors);
    }

    handleEditReport(report.report_id, updatedData);
    setEditModes('');
  };

  const handleCancelClick = () => {
    setEditModes('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const { value } = e.editProject;

    setEditedData((prevEditedData) => ({
      ...prevEditedData,
      editProject: value,
    }));
  };

  function timeToDecimal(timeString) {
    let match = /(\d+):(\d{2})/.exec(timeString);
    if (!match) {
      return 0;
    }
    let hours = match[1];
    let minutes = match[2];
    const decimal = parseInt(minutes, 10) / 60;
    return parseFloat(parseInt(hours, 10) + decimal).toFixed(2);
  }

  return (
    <Container>
      <Row>
        <Col sm={12}>
          <FormAddReport
            onSubmit={handleCreateReport}
            projects={projects}
            totalTodayHours={totalTodayHours}
            queryProjects={queryProjects}
            setQueryProjects={setQueryProjects}
            totalRecordsProjects={totalRecordsProjects}
          />
        </Col>
        <Col className="p-0 mt-4 mb-4">
          <ReportsFilterForm
            onSubmit={handlerSearchReportsFilterForm}
            resetForm={resetFilterForm}
          />
        </Col>
        <Col sm={12} className="mb-2">
          <span style={{color: "#80838e", padding: '15px'}}>Total hours: {totalHours}</span>
        </Col>
        <Col sm={12}>
          <DataReactTable
            tableName={"My Reports"}
            classList={"my-reports"}
            sortTable={true}
            search={false}
            pagination={{
              onChangePageSize: handleChangePageSize,
              onChangePage: handleChangePage,
              currentPage,
              totalRecords,
              pageLimit
            }}
            notes={notes}
            emptyStateMessage="There are no reports."
            reactTableData={{tableHeaderData: columns, tableRowsData: reports?.map((c) => {
              const isEditMode = editModes === c.report_id;

              return {...c,
                report_id: `${c.report_id}`,
                task: !isEditMode ? c.task : (
                  <>
                    <input
                      type="text"
                      name="task"
                      value={editedData.task}
                      style={{backgroundColor: "white"}}
                      onChange={(e) => handleInputChange(e, c.report_id)}
                    />
                    <span style={{color: "red"}}>{validationsErrors.task}</span>
                  </>
                ),
                project_name: !isEditMode ? c.project.name : (
                  <SelectInputRedux
                    projects={projects}
                    initialValues={{
                      editProject: {
                        value: c.project.id,
                        label: c.project.name
                      }
                    }}
                    onChange={(e) => handleSelectChange(e, c.report_id)}
                    queryProjects={queryProjects}
                    setQueryProjects={setQueryProjects}
                    totalRecordsProjects={totalRecordsProjects}
                  />
                ),
                hour: !isEditMode ? c.hour : (
                  <>
                    <input
                      type="text"
                      name="hour"
                      value={editedData.hour}
                      style={{backgroundColor: "white", maxWidth: "50px"}}
                      onChange={(e) => handleInputChange(e, c.report_id)}
                    />
                    <span style={{color: "red"}}>{validationsErrors.hours}</span>
                  </>
                ),
                actions: c.is_approved && c.is_invoiced ? null : (
                  <div className={"tableActions"}>
                    {isEditMode ? (
                      <>
                        <button className={"save btn btn-success"} onClick={() => handleSaveClick(c)}>Save</button>
                        <button className={"cancel btn btn-danger"} onClick={() => handleCancelClick()}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <ReactTooltip id="editIcon" type="dark">
                          <span style={{ color: 'white' }}>
                            {t("reactTooltips.edit")}
                          </span>
                        </ReactTooltip>
                        <button
                          data-tip
                          data-for="editIcon"
                          style={{border: 0, background: 'transparent'}}
                          onClick={() => {
                            setReportId(c.report_id);
                            setEditedData(c);
                            setEditConfirmModal(true);
                          }}
                        >
                          <PencilIcon color="#b1c3c8" size={18} />
                        </button>
                      </>
                    )}
                    {
                      !!isEditMode ? null : (
                        <>
                          <ReactTooltip id='deleteIcon' type='dark'>
                            <span style={{ color: 'white' }}>
                              {t('reactTooltips.delete')}
                            </span>
                          </ReactTooltip>
                          <button
                            data-tip
                            data-for='deleteIcon'
                            onClick={() => {
                              setReportId(c.report_id);
                              setConfirmDeleteModal(true);
                            }}
                            style={{ border: 0, background: 'transparent' }}
                          >
                            <DeleteIcon size={18} color='#b1c3c8' />
                          </button>
                        </>
                      )
                    }
                  </div>),
                rowCustomClass: { className: c.is_approved ? 'approved' : 'not-approved' },
              }
              })
            }} />
          {
            errors ? (<div>{errors}</div>) : null
          }
        </Col>

        <CustomModal
          successModal={successModal}
          textModal={`Report has been added!`}
          color={"success"}
        />
        <CustomModal
          successModal={errorModal}
          toggleCloseModal={() => setErrorModal(!errorModal)}
          color={"danger"}
          textModal={errorMessage}
        />
        <ConfirmModal
          color="danger"
          btn="Default"
          message={`Are you sure you want to delete report #${reportId}?`}
          toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
          modal={confirmDeleteModal}
          onNext={handleDeleteReport}
        />
        <CustomModal
          successModal={successDeleteModal}
          toggleCloseModal={() => {
            setSuccessDeleteModal(!successDeleteModal);
            setRefreshCounter(refreshCounter + 1);
          }}
          textModal={`Report №${reportId} was deleted successfully!`}
          color={"success"}
        />
        <CustomModal
          successModal={failedDeleteModal}
          toggleCloseModal={() => setFailedDeleteModal(!failedDeleteModal)}
          textModal={errorDeleteMessage}
          color={"danger"}
        />
        <CustomModal
          successModal={errorProjectModal}
          toggleCloseModal={() => setErrorProjectModal(!errorProjectModal)}
          color={"danger"}
          textModal={errorProjectMessage}
        />
        <CustomModal
          successModal={errorProjectIdModal}
          toggleCloseModal={() => setErrorProjectIdModal(!errorProjectIdModal)}
          color={"danger"}
          textModal={errorProjectIdMessage}
        />
        <ConfirmModal
          color="primary"
          btn="Default"
          message={`Are you sure you want to edit report #${reportId}?`}
          toggle={() => setEditConfirmModal(!editConfirmModal)}
          modal={editConfirmModal}
          onNext={handleEditClick}
        />
        <CustomModal
          successModal={successEditModal}
          toggleCloseModal={() => {
            setSuccessEditModal(!successEditModal);
            setRefreshCounter(refreshCounter + 1);
          }}
          textModal={`Report #${reportId} has been updated!`}
          color={"success"}
        />
        <CustomModal
          successModal={failedEditModal}
          toggleCloseModal={() => setFailedEditModal(!failedEditModal)}
          color={"danger"}
          textModal={errorEditMessage}
        />
      </Row>
    </Container>
  );
};

export default MyReports;