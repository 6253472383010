import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import { connect } from 'react-redux';
import Roles from '../../../../config/roles';

const SidebarContent = ({ onClick, auth }) => {
  const { role } = auth.user;
  const isFinancialRole = role === Roles.ADMIN || role === Roles.FIN || role === Roles.SALES;

  const hideSidebar = () => {
    onClick();
  };

  const renderFinancialLink = () => {
    if (isFinancialRole) {
      return (
        <SidebarLink
          title="Financial Reports"
          icon="list"
          route="/financial-reports"
          onClick={hideSidebar}
        />
      );
    }
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink
          title="Dashboard"
          icon="home"
          route="/dashboard"
          onClick={hideSidebar}
        />
        {renderFinancialLink()}
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default connect(state => ({
  auth: state.auth,
}))(SidebarContent);
