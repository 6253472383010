import React from 'react';
import { Modal } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { renderField } from '../../../../../utils/helpers';
import { connect } from 'react-redux';

let ManagementDisapproveModalForm = ({ confirm, successModal, toggleCloseModal, reportData, handleSubmit }) => {
  const onSubmit = (formValues) => {
    confirm(formValues.hours);
  };

  return (
    <Modal
      isOpen={successModal}
      toggle={toggleCloseModal}
      modalClassName="ltr-support"
      className="modal-dialog  modal-dialog--primary theme-light modal-add-new-variable"
    >
      <div className="modal__header">
        <button
          className="lnr lnr-cross modal__close-btn"
          type="button"
          onClick={toggleCloseModal}
        />
        <h4>Disapprove Report #{reportData.report_id}</h4>
      </div>
      <div className="modal__body">
        <form className="form w-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="form__form-group">
            <span className="form__form-group-label">Hours</span>
            <div className="form__form-group-field">
              <Field
                name="hours"
                component={renderField}
                type="input"
              />
            </div>
          </div>
          <div className="modal__footer d-flex justify-content-between w-100">
            <button className="btn btn-danger" type="button" onClick={toggleCloseModal}>Cancel</button>
            <button className="btn btn-primary" type="submit">Confirm</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

ManagementDisapproveModalForm = reduxForm({
  form: 'management_disapprove_modal_form',
  enableReinitialize: true
})(ManagementDisapproveModalForm);

ManagementDisapproveModalForm = connect(
  (state, props) => {
    console.log(props);
    return {
      initialValues: {
        hours: [parseInt(props?.reportData?.hour?.split(':')[0]), parseInt(props?.reportData?.hour?.split(':')[1] * 1.66666)].join('.')
      }
    }
  }
)(ManagementDisapproveModalForm);

export default ManagementDisapproveModalForm;